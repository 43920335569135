export const bankNames = [
    "BANESCO",
    "VENEZUELA",
    "MERCANTIL",
    "PROVINCIAL",
    "BNC",
    "BANCARIBE",
    "BICENTENARIO",
    "BANCAMIGA",
    "EXTERIOR",
    "FONDO COMÚN",
    "BANPLUS",
    "VENEZOLANO DE CRÉDITO",
    "SOFITASA",
    "PLAZA",
    "DEL TESORO",
    "100% BANCO",
    "ACTIVO",
    "CARONÍ",
    "DEL SUR",
    "BANCRECER",
    "INDUSTRIAL",
    "BANDEX",
    "BANCOEX",
    "BANGENTE",
    "INTERNACIONAL DE DESARROLLO",
    "AGRÍCOLA",
    "MI BANCO",
    "IMCP",
    "BANAVIH",
    "BANFANB",
    "BOD"
  ];
  

  export const relationships = [
    "Abuelos",
    "Ahijados",
    "Amigos",
    "Comercio/Socio",
    "Comprador/Servicios",
    "Cuñados",
    "Donantes/Fondos Benéficos",
    "Empleados",
    "Esposo(a)/Pareja",
    "Exmarido/Exesposa/Expareja",
    "Hermanos",
    "Hijastros",
    "Hijos",
    "Padrino/Madrina",
    "Nietos",
    "Novio(a)",
    "Yerno/Nuera",
    "Padrastros",
    "Padres",
    "Primos",
    "Sobrinos",
    "Suegros",
    "Tíos",
    "Yo mismo"
];
