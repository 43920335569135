import React from "react";
import { FaUser, FaHourglassEnd } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import one from "../assets/one.png";
import two from "../assets/two.png";
import three from "../assets/three.png";
import { Link } from "react-router-dom";

const HowWorks = () => {
  return (
    <div className="how_works mt-10">
      <div className="canvas canvas_col">
        <h1 className="text-center fw-bold">¿Cómo Funciona?</h1>

        <div className="grid mt-5">
          <div className="text-center Card">
            <img src={one} width={60} />
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/registro"
            >
              <h2 className="fs-4 fw-bold mt-2">Abre tu Cuenta</h2>
            </Link>
            <p className="mt-2 font2">
              ¡Es Gratis! Y podrás empezar a gestionar tus Envíos a Venezuela y otros países de Latinoamérica en minutos.
            </p>
          </div>
          <div className="text-center Card">
            <img src={two} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Acredita tu Identidad</h2>
            <p className="mt-2 font2">
            De esta manera, evitamos el fraude y ofrecemos una plataforma 100% segura para todos los usuarios.
            </p>
          </div>
          <div className="Card text-center">
            <img src={three} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Realiza tu Envío</h2>
            <p className="mt-2 font2">
            Enviamos a cualquier Entidad Bancaria de Venezuela y a otros países de Latinoamérica.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
