import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import registerimg from "../assets/abrir.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import Logo from "../assets/Logo.svg";
import { useAuthContext } from "../Contexts/AuthContext";

const Register = ({ }) => {
  const [creds, setCreds] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [robot, setRobot] = useState(false);
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const HandleRegister = async (e) => {
    e.preventDefault();
    if (
      creds?.email?.length > 0 &&
      creds?.password?.length > 0 &&
      creds?.cpassword?.length > 0 &&
      robot
    ) {
      if (document.getElementById("flexCheckDefault").checked) {
        if (creds.password === creds.cpassword) {
          setloading(true);
          try {
            await axios.post(
              process.env.REACT_APP_BASE_URL + "/api/auth/register",
              {
                ...creds,
                email: creds.email.toLowerCase(),
              }
            );
            setSuccess(true);
            // setCreds({ residence: "ESPAÑA" });
            document.querySelectorAll("input").forEach((inp) => {
              inp.value = "";
            });
            setloading(false);
            toast.success(
             "Activa tu Cuenta Transferven desde tu email para poder acceder.", {
              position: "top-center",
              theme: "dark"
            }
            );
          } catch (err) {
            console.log(err);
            setloading(false);
            toast.error(err.response?.data?.error, {
              position: "top-center",
              theme: "dark"
            });
            setSuccess(false);
          }
        } else {
          toast.error("Contraseñas no coinciden!", {
            position: "top-center",
            theme: "dark"
          });
        }
      } else {
        toast.error(
         "Acepta nuestros Términos y Condiciones", {
          position: "top-center",
          theme: "dark"
        }
        );
      }
    }
    else {
      toast.error("Completar todos los datos!",{
        position: "top-center",
        theme: "dark"
      });
    }
  };

  const HandleChange = (e) => {
    setCreds((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  function onChange(value) {
    setRobot(value);
  }

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  if (user) return <Navigate to={"/"} />;

  return (
    <div className="Register bg-white column-resp">
      <PageBanner title={"Abre Tu Cuenta"} img={registerimg} />
      <ToastContainer />

      <form
        className="d-flex justify-content-between canvas col_resp py-5"
        onSubmit={HandleRegister}
      >
        <div className="register_1 text-center mt-5">
          <img src={Logo} alt="Transferven logo" height={40} />
          {/* <Logo /> */}
          <p className="mt-2 fs-5">
            Abre tu Cuenta y empieza a enviar dinero a tus familiares y amigos
            en pocos minutos.
          </p>
        </div>
        <div className="register_2">
          <div className="d-flex align-items-center flex-wrap register-fields mt-1">
            <input
              type={"email"}
              placeholder="E-mail"
              className="form-control field rounded-0 border-0 mt-2"
              id="email"
              onChange={(e) => HandleChange(e)}
              
            />
            <p className="small text-danger ms-3">+18 años.</p>
          </div>
          <input
            type={"password"}
            placeholder="Crea tu contraseña"
            className="form-control field rounded-0 border-0 mt-4 w-100"
            id="password"
            onChange={(e) => HandleChange(e)}
            autoComplete="false"
            
          />
          <input
            type={"password"}
            placeholder="Confirmar Contraseña"
            className="form-control field rounded-0 border-0 mt-3 w-100"
            id="cpassword"
            onChange={(e) => HandleChange(e)}
            autoComplete="false"
            
          />
          <div className="d-flex align-items-start form-check mt-3">
            <input
              className="form-check-input mb-0"
              style={{ width: "25px", height: "20px", border: "2px solid black" }}
              type="checkbox"
              id="flexCheckDefault"
            />
            <label
              className="form-check-label text-muted ms-2"
              htmlFor="flexCheckDefault"
              style={{width: "calc(100% - 35px)"}}
            >
              Haciendo click aquí, aceptas nuestros{" "}
              <Link to="/terms" target="_blank">
                Términos y Condiciones
              </Link>{" "}
              y
              <Link to={"/protection"} target="_blank">
                {" "}
                Política general en materia de Protección de Datos
              </Link>{" "}
              de Transferven y nos autorizas a contactarte por E-mail, SMS y
              Whatsapp.
            </label>
          </div>
          <ReCAPTCHA
            sitekey="6Ld2YggpAAAAACwfxHxBPm1_LxtxDO17jtULGO3T"
            onChange={onChange}
            onExpired={(e) => e.reset()}
            className="mt-3"
            
          />

          <p className="text-danger">{!robot && "Verify Recaptcha!"}</p>
          <button
            type="submit"
            className="continue mt-3 d-block mx-auto w-auto px-3"
            disabled={loading}
          >
            {loading ? "ENVIANDO" : "REGISTRARME"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default (Register);
