import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../Contexts/AuthContext";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import axios from "axios";
import img1 from "../assets/digitel.png"
import img2 from "../assets/MOVILNET.png"
import img3 from "../assets/movistar.png"
import img4 from "../assets/simpletv.png"
import { toast } from "react-toastify";
import Cookies from "js-cookie";


const Calculator3 = ({ }) => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [numero, setNumber] = useState("")
    const [loading, setLoading] = useState(false);
    const [total, settotal] = useState("");
    const [amount, setAmount] = useState("");
    const [operator, setOperator] = useState("Digitel")
    const exchangerate = useRef();
    const { userdata, user } = useAuthContext();
    const [terms1, setTerms1] = useState(false);
    const [terms2, setTerms2] = useState(false);


    const amountsArr = [
        ["50", "100", "200", "300", "600", "800"],
        ["80", "120", "160", "320", "600", "1800", "2920"],
        ["45", "90", "135", "180", "225"],
        ["15", "50", "100", "200", "300"],
    ]


    useEffect(() => {
        const Fetchrate = async () => {
            const { data } = await axios.get(
                process.env.REACT_APP_BASE_URL + "/api/rates/getrates"
            );

            exchangerate.current.innerHTML = data.data[0].EXCHRATE;
        }

        Fetchrate()
    }, [])


    const handleSubmit = async () => {
        if (Object.values(userdata).length > 2) {
            if ((operator === "SimpleTV" && numero.length === 12) || (operator !== "SimpleTV" && numero.length === 11)) {
                if (total.length > 0) {
                    if (userdata.kyc === true) {
                        try {
                            setLoading(true)
                            await axios.post(process.env.REACT_APP_BASE_URL + "/api/orders/recharge",
                                {
                                    phone: numero,
                                    operator: (operator),
                                    total: total,
                                    amount: amount,
                                    email: userdata.email,
                                    userId: userdata.uid
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        token: Cookies.get("token"),
                                    },
                                })

                            setNumber("")
                            settotal("")
                            setAmount("")
                            setLoading(false)
                            toast.success("Orden Recibida", {
                                position: "top-center",
                                theme: "dark"
                            });
                            setTerms2(false)
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        toast.error("¡USUARIO PENDIENTE DE VERIFICACIÓN DE IDENTIDAD!", {
                            position: "top-center",
                            theme: "dark"
                        });
                    }

                } else {
                    toast.error("Completar todos los datos!", {
                        position: "top-center",
                        theme: "dark"
                    });
                }
            } else {
                toast.error(
                    operator !== "SimpleTV" ? "Número Telefónico DEBE TENER 11 DÍGITOS!" : "TARJETA DE ACCSO DEBE TENER 12 DÍGITOS!", {
                    position: "top-center",
                    theme: "dark"
                })
            }
        } else {
            navigate("/login")
        }
    }

    return (
        <div className="rounded border calculator3 mt-3 OrderSection">
            <div className="mt-3 px-3">
                <label>Selecciona Operador:</label>
                <div className="mt-2 d-flex col-12 justify-content-center flex-wrap align-items-center">
                    <img src={img1} alt="Digitel logo" width={100} className={`${operator === "Digitel" ? "border" : ""} me-md-3 me-2 p-1`} onClick={() => {
                        setIndex(0); setOperator("Digitel");
                        settotal((Number(amountsArr[0][0] /
                            exchangerate.current.innerHTML.replaceAll(",", "cc").replaceAll(".", "dd")
                                .replaceAll("cc", ".").replaceAll("dd", "")) + 2.5).toLocaleString("es-ES", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }))
                    }} type="button" />
                    <img src={img2} alt="Movilnet logo" width={110} className={`${operator === "Movilnet" ? "border" : ""} me-md-3 me-2 p-1`} onClick={() => {
                        setIndex(1); setOperator("Movilnet");
                        settotal((Number(amountsArr[1][0] /
                            exchangerate.current.innerHTML.replaceAll(",", "cc").replaceAll(".", "dd")
                                .replaceAll("cc", ".").replaceAll("dd", "")) + 2.5).toLocaleString("es-ES", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }))
                    }} type="button" />
                    <img src={img3} alt="Movistar logo" width={110} className={`${operator === "Movistar" ? "border" : ""} me-md-3 me-2 p-1`} onClick={() => {
                        setIndex(2); setOperator("Movistar");
                        settotal((Number(amountsArr[2][0] /
                            exchangerate.current.innerHTML.replaceAll(",", "cc").replaceAll(".", "dd")
                                .replaceAll("cc", ".").replaceAll("dd", "")) + 2.5).toLocaleString("es-ES", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }))
                    }} type="button" />
                    <img src={img4} alt="Simpletv logo" width={130} className={`${operator === "SimpleTV" ? "border" : ""} me-md-3 me-2 p-1`} onClick={() => {
                        setIndex(3); setOperator("SimpleTV");
                        settotal((Number(amountsArr[3][0] /
                            exchangerate.current.innerHTML.replaceAll(",", "cc").replaceAll(".", "dd")
                                .replaceAll("cc", ".").replaceAll("dd", "")) + 2.5).toLocaleString("es-ES", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }))
                    }} type="button" />
                </div>

                <label className="mt-2">{operator === "SimpleTV" ? "Tarjeta de Acceso" : "Número Telefónico"}</label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control"
                        id="number"
                        inputMode="numeric"
                        placeholder={operator === "SimpleTV" ? "" : "Ej. 0412XXXXXXX"}
                        value={numero}
                        onChange={(e) => {
                            if ((operator === "SimpleTV" && e.target.value.length <= 12) ||
                                (operator !== "SimpleTV" && e.target.value.length <= 11)) setNumber(e.target.value)
                        }}
                                                disabled={user ? !userdata.kyc : false}

                    />
                </div>
            </div>


            <div className="mt-3 px-3 d-flex flex-wrap">
                {amountsArr[index].map((elem, i) => {
                    return <div key={i} className="col-lg-3 col-md-4 col-6 py-2">
                        <input type="radio" name="amount"
                            className="inputradios"
                            onChange={() => {
                                settotal((Number(amountsArr[index][i] /
                                    exchangerate.current.innerHTML.replaceAll(",", "cc").replaceAll(".", "dd")
                                        .replaceAll("cc", ".").replaceAll("dd", "")) + 2.5).toLocaleString("es-ES", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }))
                                setAmount(amountsArr[index][i])
                            }
                            }
                                                    disabled={user ? !userdata.kyc : false}

                        />
                        <label className="ms-2">{elem} VED</label>
                    </div>
                })
                }
            </div>
            <p className="text-muted mt-3 px-3">
                Comisión:{" "}
                <span className="fw-bold" id="totalFees">2,50 €</span>
            </p>
            <p className="text-muted px-3">
                Tasa: 1 EUR = {" "}
                <span
                    className="fw-bold"
                    id="exchangerate"
                    ref={exchangerate}
                ></span> €
            </p>

            <div className="mt-3 px-3">
                <label>Total a pagar </label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control bg-white"
                        id="total"
                        value={total}
                    />
                    <div className="curr">EUR</div>
                </div>
            </div>
            <p className="text-muted px-2 small">
                *Resultado sujeto a tasas y tipos de cambio variables.
            </p>
            <button
                className="continue"
                disabled={loading || (user ? !userdata.kyc : false)}
                onClick={() => {
                    if (Object.values(userdata).length > 2) {
                        if ((operator === "SimpleTV" && numero.length === 12) || (operator !== "SimpleTV" && numero.length === 11)) {
                            if (total.length > 0) {
                                if (userdata.kyc === true) {
                                    setTerms1(true)
                                } else {
                                    toast.error("¡USUARIO PENDIENTE DE VERIFICACIÓN DE IDENTIDAD!", {
                                        position: "top-center",
                                        theme: "dark"
                                    });
                                }

                            } else {
                                toast.error("Completar todos los datos!", {
                                    position: "top-center",
                                    theme: "dark"
                                });
                            }
                        } else {
                            toast.error(
                                operator !== "SimpleTV" ? "Número Telefónico DEBE TENER 11 DÍGITOS!" : "TARJETA DE ACCSO DEBE TENER 12 DÍGITOS!", {
                                position: "top-center",
                                theme: "dark"
                            })
                        }
                    } else {
                        navigate("/login")
                    }
                }}>
                {loading ? "Enviando" : "Continuar"}
            </button>




         


            {terms1 && (
                <div
                    className="modal show  d-flex justify-content-center 
          align-items-center"
                    style={{ height: "100vh", background: "rgba(0,0,0,0.3)" }}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog payment_dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">
                                    Términos y Condiciones
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setTerms1(false);
                                    }}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <b>RECORDATORIO:</b> Los pagos deben realizarse desde una cuenta bancaria o tarjeta particular <b>A TU NOMBRE.</b> Los pagos realizados con terceros no serán aceptados, gastos por gestión serán aplicados y se devolverán a origen en un plazo de 15 días hábiles.
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn bg-p text-white"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setTerms2(true)
                                        setTerms1(false);
                                    }}
                                >
                                    Entiendo
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ms-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setTerms1(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {terms2 && (
                <div
                    className="modal show  d-flex justify-content-center 
      align-items-center"
                    style={{ height: "100vh", background: "rgba(0,0,0,0.3)" }}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog payment_dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">
                                    Términos y Condiciones
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setTerms2(false);
                                    }}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <b>RECORDATORIO:</b> Indicar en el concepto/observaciones/notas de tu pago, <b>ÚNICA Y EXCLUSIVAMENTE</b> el número de Orden indicado por el sistema. Pagos sin concepto u otras referencias generarán un atraso en el proceso de verificación de su pago y gastos por gestión.
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn bg-p text-white"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    disabled={loading}
                                >
                                    {loading ? "Enviando" : "Entiendo"}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ms-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setTerms2(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default (Calculator3);
