import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../Contexts/AuthContext";

const Verify = () => {
  const { userdata, user } = useAuthContext();
  const kyc = useRef(window.location.search?.split("id=").length > 0 ? "https://safeonboarding.net/VideoIdentification/Index/" + window.location.search?.split("id=")[1] : userdata.verificationId);


  var eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod === "attachEvent"
    ? "onmessage"
    : "message";
  eventer(messageEvent, async function (e) {
    if (e.data && e.data.code) {
      try {
        //code = 0 => ERROR
        if (e.data.code == 0) {
          alert("Error event received: " + e.data.message);
        }
        //code = 1 => FINISHED OK
        else if (e.data.code == 1) {
          console.log("Finish");
          // window.location.href = "{OK_PAGE}";
        }
        //code = 2 => INFORMATION
        else if (e.data.code == 2) {
          console.log("Completed");
          // alert("Information event received: " + e.data.message);
        }
      } catch (error) {
        // alert("event data cannot be parsed: " + e.data);
      }
    }
  });

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  if (!user) return <Navigate to={`/login?callback=verify`} />
  if (userdata.kyc === true) return <Navigate to={"/"} />

  return (
    <div style={{ height: "100vh" }}>
      <iframe style={{ width: "100%", height: "100%" }} src={kyc.current}
        scrolling="no" allow="microphone; camera" />

    </div>
  );
};

export default (Verify);
