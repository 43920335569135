import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import whyus from "../assets/whyyus.jpg";
import play from "../assets/play.png";
import apple from "../assets/app.png";
import cios from "../assets/cios.png";
import { Link } from "react-router-dom";


const WhyUs = () => {
  return (
    <div className="Whyus mt-10 py-5 fs-60 column-resp">
      <div
        className="d-flex justify-content-between canvas canvas_col"
        style={{ position: "relative" }}
      >
        <img
          src={whyus}
          alt="latinotransfer img"
          className="w-50 me-5 rounded"
          style={{
            objectFit: "cover",
            height: "100vh",
          }}
        />
        <div className="why_content w-50">
          <h1 className="fw-bold">Tus Envíos 100% Garantizados</h1>
          <p className="font2 mt-2 fs-6 text-muted">
            Transferven te ofrece un servicio de envíos seguro, innovador, eficiente y con mayores beneficios. Ofrecemos soluciones simples para el cliente moderno. Seas particular o mayorista, todo lo que necesitas en un solo lugar, con tu Cuenta Transferven
          </p>

          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">7 años a tu Servicio</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Transferven es gestionado por un equipo de profesionales con gran trayectoria en el sector de las remesas hacia Venezuela y otros países de Latinoamérica. Nuestro servicio está disponible para todos los países de la Unión Europea.
            </p>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Tu Envío Garantizado</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Como Empresa Regulada, cumplimos con los más estrictos Protocolos de Seguridad, para garantizar una experiencia 100% Segura a todos los usuarios.
            </p>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Atención Personalizada</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              En Transferven cuentas con una Atención directa y a tiempo real a través del Chat Online, para cualquier consulta o duda que necesites aclarar.
            </p>{" "}
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Proceso Simple</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Desde la comodidad de tu hogar y a través de tu móvil. Gestiona tus Envíos de manera rápida y sencilla.
            </p>{" "}
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Atendemos a Mayoristas</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Contamos con un servicio especial para atender a Mayoristas del sector. Si eres uno de ellos no dudes en contactar con un Agente.
            </p>{" "}
          </div>

          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Criptomonedas</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              En nuestra sección de Venta de Criptomonedas podrás comprar de manera Legal y de forma Regulada hasta 100 activos digitales directamente hacia tu wallet de preferencia.
            </p>{" "}
            <Link to="/crypto" className="mt-2 continue" style={{width: "fit-content"}}>
              Criptomonedas
            </Link>
          </div>


          <div className="mt-3">
            {/* <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Descarga nuestra App</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Descubre nuestra App. Gestiona y controla todos tus Envíos fácilmente, accediendo de manera rápida a tu Cuenta Transferven. Disponible para Android/iOS.
            </p>{" "} */}
            {/* <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Descarga nuestra App</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Gestiona y controla todos tus Envíos fácilmente, accediendo de manera rápida a tu Cuenta Transferven. Disponible para Android. Próximamente también para iOS
            </p>{" "} */}
            {/* <div className="d-flex mt-3 w-100">
              <Link to={"https://play.google.com/store/apps/details?id=com.mohdhasnainn.transferven"} target="_blank">
               <img src={play} style={{ width: "150px" }} className="me-3" />
              </Link> 
            </div> */}
          </div>


        </div>

      </div>
    </div>
  );
};

export default WhyUs;
