import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Calculator from "./Calculator";
import Navbar from "./Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import play from "../assets/play.png";
import apple from "../assets/app.png";
import cios from "../assets/cios.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../Contexts/AuthContext";
// import './styles.css';

const Hero = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  return (
    <div className="Hero_section py-4">
      <Navbar />
      <div
        className="d-flex justify-content-between align-items-center 
      hero_main canvas"
      >
        <div className="w-50">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="pb-2">
                <h1 className="hero_h">
                  Tus Envíos de Dinero en Minutos. Simple y Seguro.
                </h1>
                <div className="mt-2">
                  <p>
                    Abre tu Cuenta Transferven y comienza a envíar dinero a tus
                    familiares y amigos. Transferencias Rápidas y Garantizadas.
                  </p>
                  <button
                    className="px-3 btn text-white mt-2 special_btn rounded-pill"
                    style={{ border: "1.99px solid white" }}
                    onClick={() => navigate("/registro")}
                  >
                    Abrir Cuenta
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pb-2">
                <h1 className="hero_h">
                  Envía a Venezuela y otros países de Latinoamérica.
                </h1>
                <div className="mt-3">
                  <p>
                  Servicio disponible para toda la Unión Europea. Más de 7 años brindando soluciones a nuestros clientes.
                  </p>
                </div>
                <button
                  className="px-3 btn text-white mt-2 special_btn rounded-pill"
                  style={{ border: "1.99px solid white" }}
                  onClick={() => navigate(user ? "/recogida" : "/registro")}
                >
                  Abrir Cuenta{" "}
                </button>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* <p className="mt-5">Descarga nuestra App</p>
          <div className="d-flex mt-2">
            <Link to={"https://play.google.com/store/apps/details?id=com.mohdhasnainn.transferven"} target="_blank"> 
            <img src={play} width={150} className="me-3" />
            </Link>
          </div> */}
        </div>
        <Calculator btn={true} />
      </div>
    </div>
  );
};

export default Hero;
