import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { MdCall, MdMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import geslopd from "../assets/geslopd.png";
import exp from "../assets/EXPERIENCE.png";
import FooterLogo from "../assets/Logo2.svg";


const Footer = () => {
  return (
    <footer className="footer mt-10 pt-4 pb-2">
      <div className="canvas">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="footer-col">
            <img src={FooterLogo} alt="Transferven" height={45} />
            {/* {<FooterLogo />} */}

            <div className="d-flex mt-3 mb-2">
              <a
                href="https://www.instagram.com/transferveneuropa"
                target="_blank"
                className="social_icon"
              >
                <BsInstagram />
              </a>
              <a
                href="https://www.facebook.com/transferveneuropa"
                target="_blank"
                className="social_icon ms-2"
              >
                <FaFacebookF />
              </a>
              {/* <a
                href="https://twitter.com/postalven"
                target="_blank"
                className="social_icon ms-2"
              >
                <FaXTwitter />
              </a> */}
            </div>
            {/* <div className="d-flex mt-3">
            <FaLocationDot className="me-3"  size={20} color="#fc364e" />
              <p className="mb-0"> Garcilaso De La Vega 21<br />
                Local 12, 38005<br />
                Santa Cruz de Tenerife<br />
              </p>
            </div> */}
           
            <div className="mt-2 d-flex align-items-center">
              <AiOutlineClockCircle size={20} color="#fc364e" />
              <p className="text-white ms-3">L a V de 10 a 18 Hrs.</p>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <MdCall size={20} color="#fc364e" />
              <p className="text-white ms-3">+34 91 993 13 12</p>
            </div>
            <div className="mt-2">
              <MdMailOutline size={20} color="#fc364e" />
              <Link to={"/contact"} className="text-white ms-3">
                info.es@transferven.com
              </Link>
            </div>
          </div>
          <div className="footer-col">
            <h2>Ayuda</h2>
            <div className="mt-3">
              <a
                href="https://wa.me/34620818181?text=¡Hola!"
                className="text-white"
                target="_blank"
              >
                Chat online
              </a>
            </div>
            <div className="mt-2">
              <Link to={"/contact"} className="text-white">
                Contacto
              </Link>
            </div>
            <div className="mt-2">
              <Link to={"/terms"} className="text-white">
                Términos y Condiciones
              </Link>
            </div>
          </div>

          <div className="footer-col">
            <h2>Información</h2>
            <div className="mt-2">
              <Link to={"/privacy"} className="text-white">
                Política de Privacidad
              </Link>
            </div>
            <div className="mt-2">
              <Link to={"/avisolegal"} className="text-white">
                Aviso Legal
              </Link>
            </div>
            <div className="mt-2">
              <Link to={"/cookies"} className="text-white">
                Política de Cookies
              </Link>
            </div>

            <div className="mt-2">
              <Link to={"/protection"} className="text-white">
                Protección de Datos
              </Link>
            </div>
          </div>

          <div className="d-flex footer-col">
            <img src={geslopd} height={150} width={150} className="me-2" />
            <img src={exp} height={150} width={150} />
          </div>
        </div>
      </div>
      <p className="mt-3 py-3 px-3 text-center canvas" style={{fontSize: 11.5}}>
      Copyright © 2017 - 2024 Euro Digital Multiservices S.L. · Imágenes de www.freepik.com · Transferven.com es una marca registrada de la empresa Euro Digital Multiservices S.L. (CIF B13852330). Euro Digital Multiservices S.L. es una Entidad inscrita en el Registro de Proveedores de Cambio de Moneda Virtual por Moneda Fiduciaria. Cumplimos con la regulación vigente en materia de Prevención de Blanqueo de Capitales y Prevención en Financiación del Terrorismo.
      </p>
    </footer>
  );
};

export default Footer;
