import React from "react";
import client from "../assets/client.jpg";
import carga from "../assets/chat.png";
import { useNavigate } from "react-router-dom";

const Speciality = () => {
  const navigate = useNavigate();

  return (
    <div className="canvas mt-10">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="fw-bold">Promociones Especiales</h1>
          <p className="mt-2 font2">
          En Transferven queremos ofrecerte las mayores ventajas. Seas Particular o Mayorista, tenemos siempre las mejores opciones para ti.
          </p>
        </div>
      </div>

      <div
        className="d-flex justify-content-between 
      align-items-center flex-wrap mt-4"
      >
        {/* <div className="special-card  mt-3"> */}
          <div
            className="d-flex align-items-center special-card 
        rounded text-white px-4 py-5 mt-3"
            style={{ background: `url(${client})` }}
          >
            <div>
              <p className="fw-bold fs-5">Nuevos Clientes</p>
              <p className="fs-5">Bienvenida Especial</p>
              <p className="fw-bold fs-5">Primer Envío Sin Comisión</p>
              <button
                className="px-3 btn text-white mt-4 special_btn rounded-pill"
                onClick={() => navigate("/registro")}
                style={{ border: "1.99px solid white" }}
              >
               Abrir Cuenta
              </button>
            </div>
          </div>
          {/* <p className="mt-1">*Envíos mayores a 10kg</p> */}
        {/* </div> */}
        <div
          className="d-flex align-items-center special-card 
        rounded text-white px-4 py-5 mt-3"
          style={{ background: `url(${carga})` }}
        >
          <div>
            <p className="fw-bold fs-5">Atención Personalizada</p>
            <p className="fs-5">Servicio Web 24 Hrs.</p>
            <a href="https://wa.me/34672936101?text=¡Hola!" target="_blank"
              className="px-3 btn text-white mt-4 special_btn rounded-pill"
              style={{ border: "1.99px solid white", textDecoration: "none"}}
            >
              Chat Online
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speciality;
